import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSpinner from "../../../components/common/spinner/CustomSpinner";
import ClientFoldersLayout from "../../../components/layout/client-folders-layout/ClientFoldersLayout";
import CustomNavbar from "../../../components/layout/navbar/Navbar";
import { clearFetchFilteredEntityAdStates } from "../../../redux/filtered-entities/filtered.entities.action";
import { getAllCollections } from "../../../services/collections.service";
import { getAllEntities } from "../../../services/entities.service";
import { fetchProfile } from "../../../services/profile.service";
import {
  ENTITY_TYPES,
  EVENT_TYPES,
  STD_ERROR_MESSAGE
} from "../../../utils/constant";
import {
  getImageUrl,
  getKeyword,
  getUserId,
  removeKeyword
} from "../../../utils/utils";

const Collections = () => {
  const collectionsState = useSelector((state) => state.collectionsEm);
  const entityContainerState = useSelector((state) => state.entitiesAd);
  const profileState = useSelector((state) => state.readProfileEm);

  const profileData = profileState?.response?.data;
  const dispatch = useDispatch();

  const [collectionLoading, setCollectionLoading] = useState(true);
  const [entityLoading, setEntityLoading] = useState(true);

  const [organizedCollections, setOrganizedCollections] = useState([]);
  const [organizedEntities, setOrganizedEntities] = useState([]);
  const [promotions, setPromotions] = useState([]);

  const collections = collectionsState?.response?.data;
  const entityContainers = entityContainerState?.response?.data;

  const searchedEntitiesState = useSelector((state) => state.filteredAd);
  const searchedEntity = searchedEntitiesState?.response.data;

  const [filteredEntities, setFilteredEntities] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (isEmpty(collectionsState.response) || collectionsState.reload)
      removeKeyword();
    dispatch(getAllCollections());
    if (isEmpty(entityContainerState.response) || entityContainerState.reload)
      removeKeyword();
    dispatch(getAllEntities());
    if (isEmpty(profileState.response)) {
      dispatch(fetchProfile(getUserId()));
    }
    return () => {
      dispatch(clearFetchFilteredEntityAdStates());
      removeKeyword();
    };
  }, []);

  useEffect(() => {
    if (!collectionsState.loading && !isEmpty(collectionsState.response)) {
      setCollectionLoading(false);
      setOrganizedCollections(organizeCollection(collections));
    }
    if (!collectionsState.loading && collectionsState.error) {
      setCollectionLoading(false);
      toast.error(
        collectionsState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [collectionsState]);

  useEffect(() => {
    if (!profileState.loading && !isEmpty(profileState.error)) {
      toast.error("Couldn't load user profile.");
    }
  }, [profileState]);

  useEffect(() => {
    if (
      !entityContainerState.loading &&
      !isEmpty(entityContainerState.response)
    ) {
      setEntityLoading(false);
      setOrganizedEntities(organizeEntities(entityContainers));
    }
    if (!entityContainerState.loading && entityContainerState.error) {
      setEntityLoading(false);
      toast.error(
        entityContainerState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [entityContainerState]);

  useEffect(() => {
    if (
      !searchedEntitiesState.loading &&
      !isEmpty(searchedEntitiesState.response)
    ) {
      setFilteredEntities(organizeFilteredEntities(searchedEntity));
    }
    if (!searchedEntitiesState.loading && searchedEntitiesState.error) {
      toast.error(
        searchedEntitiesState?.error?.data?.error_data || STD_ERROR_MESSAGE
      );
    }
  }, [searchedEntity]);

  function organizeCollection(collections) {
    if (!collections) return [];

    const weddingEvents = [];
    const birthdayEvents = [];
    const otherEvents = [];
    const apartmentEvents = [];
    const bnichapters = [];

    const publishedEvents = collections.filter(
      (container) => container?.permalink?.publish
    );

    console.log(publishedEvents);

    for (const collection of collections) {
      if (collection.event.category_id === EVENT_TYPES.wedding)
        weddingEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.birthday)
        birthdayEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.event)
        otherEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.apartment)
        apartmentEvents.push(collection);
      else if (collection.event.category_id === EVENT_TYPES.bnichapter)
        bnichapters.push(collection);
    }

    const resultCollections = [];
    if (!isEmpty(weddingEvents))
      resultCollections.push({ name: "Weddings", events: weddingEvents });
    if (!isEmpty(birthdayEvents))
      resultCollections.push({ name: "Birthdays", events: birthdayEvents });
    if (!isEmpty(otherEvents))
      resultCollections.push({ name: "Events", events: otherEvents });
    if (!isEmpty(apartmentEvents))
      resultCollections.push({ name: "Apartments", events: apartmentEvents });
    if (!isEmpty(bnichapters))
      resultCollections.push({
        name: "Business Networks",
        events: bnichapters
      });

    //fixing the items on Top most position

    // const fixedItems = ["Events", "Business Networks"];
    // const fixedItemsFound = [];

    // fixedItems.forEach((fixedItemName) => {
    //   const fixedItemIndex = resultCollections.findIndex(
    //     (item) => item.name === fixedItemName
    //   );

    //   if (fixedItemIndex !== -1) {
    //     const fixedItem = resultCollections.splice(fixedItemIndex, 1)[0];
    //     fixedItemsFound.push(fixedItem);
    //   }
    // });
    for (let i = resultCollections.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [resultCollections[i], resultCollections[j]] = [
        resultCollections[j],
        resultCollections[i]
      ];
    }

    // if (fixedItemsFound.length > 0) {
    //   setPromotions((prev) => [...prev, ...fixedItemsFound]);
    // }

    return resultCollections;
  }

  function organizeEntities(entityContainers) {
    if (!entityContainers) return [];

    const education = [];
    const events = [];
    const mnc = [];
    const food = [];
    const marketing = [];
    const property = [];
    const health = [];
    const business = [];
    const finance = [];
    const wedding = [];
    const photographers = [];
    const bakers = [];
    const makeup = [];
    const resorts = [];
    const caterers = [];
    const fashion = [];
    const travel = [];
    const artists = [];
    const restraunts = [];
    const florists = [];
    const gifting = [];
    const jewelry = [];
    const music = [];

    const publishedEntities = entityContainers.filter(
      (container) => container?.permalink?.publish
    );
    for (const publishedEntity of publishedEntities) {
      if (publishedEntity.entity.entity_type === ENTITY_TYPES.education)
        education.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.events_celebrations
      )
        events.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.mnc_hr)
        mnc.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.food_hospitality
      )
        food.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.marketing)
        marketing.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.property)
        property.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.health_wellness
      )
        health.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.business_owners
      )
        business.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.finance)
        finance.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.wedding_planners
      )
        wedding.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.photographers
      )
        photographers.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.bakers_chocolatiers
      )
        bakers.push(publishedEntity);
      else if (
        publishedEntity.entity.entity_type === ENTITY_TYPES.makeup_stylists
      )
        makeup.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.resorts)
        resorts.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.caterers)
        caterers.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.fashion)
        fashion.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.travel)
        travel.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.artists)
        artists.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.restraunts)
        restraunts.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.florists)
        florists.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.gifting)
        gifting.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.jewelry)
        jewelry.push(publishedEntity);
      else if (publishedEntity.entity.entity_type === ENTITY_TYPES.music)
        music.push(publishedEntity);
    }

    const resultEntities = [];
    if (!isEmpty(education))
      resultEntities.push({ name: "Education", entities: education });
    if (!isEmpty(events))
      resultEntities.push({ name: "Events & Celebrations", entities: events });
    if (!isEmpty(mnc)) resultEntities.push({ name: "MNC & HR", entities: mnc });
    if (!isEmpty(food))
      resultEntities.push({ name: "Food & Hospitality", entities: food });
    if (!isEmpty(marketing))
      resultEntities.push({ name: "Marketing", entities: marketing });
    if (!isEmpty(property))
      resultEntities.push({ name: "Property", entities: property });
    if (!isEmpty(health))
      resultEntities.push({ name: "Health & wellness", entities: health });
    if (!isEmpty(business))
      resultEntities.push({ name: "Business Owners", entities: business });
    if (!isEmpty(finance))
      resultEntities.push({ name: "Finance", entities: finance });
    if (!isEmpty(wedding))
      resultEntities.push({ name: "Wedding Planners", entities: wedding });
    if (!isEmpty(photographers))
      resultEntities.push({ name: "Photographers", entities: photographers });
    if (!isEmpty(bakers))
      resultEntities.push({ name: "Bakers Chocolatiers", entities: bakers });
    if (!isEmpty(makeup))
      resultEntities.push({ name: "Makeup Stylists", entities: makeup });
    if (!isEmpty(resorts))
      resultEntities.push({ name: "Resorts", entities: resorts });
    if (!isEmpty(caterers))
      resultEntities.push({ name: "Caterers", entities: caterers });
    if (!isEmpty(fashion))
      resultEntities.push({ name: "Fashion", entities: fashion });
    if (!isEmpty(travel))
      resultEntities.push({ name: "Travel", entities: travel });
    if (!isEmpty(artists))
      resultEntities.push({ name: "Artists", entities: artists });
    if (!isEmpty(restraunts))
      resultEntities.push({ name: "Restaurants", entities: restraunts });
    if (!isEmpty(florists))
      resultEntities.push({ name: "Florists", entities: florists });
    if (!isEmpty(gifting))
      resultEntities.push({ name: "Gifting", entities: gifting });
    if (!isEmpty(jewelry))
      resultEntities.push({ name: "Jewelry", entities: jewelry });
    if (!isEmpty(music))
      resultEntities.push({ name: "Music", entities: music });

    //fixing the item on Top most position

    // const fixedItems = ["Property"];
    // const fixedItemsFound = [];

    // fixedItems.forEach((fixedItemName) => {
    //   const fixedItemIndex = resultEntities.findIndex(
    //     (item) => item.name === fixedItemName
    //   );

    //   if (fixedItemIndex !== -1) {
    //     const fixedItem = resultEntities.splice(fixedItemIndex, 1)[0];
    //     fixedItemsFound.push(fixedItem);
    //   }
    // });
    for (let i = resultEntities.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [resultEntities[i], resultEntities[j]] = [
        resultEntities[j],
        resultEntities[i]
      ];
    }

    // if (fixedItemsFound.length > 0) {
    //   setPromotions((prev) => [...prev, ...fixedItemsFound]);
    // }
    return resultEntities;
  }

  const handleCollectionTrigger = (collection) => {
    if (collection?.permalink?.publish) {
      history.push(`/${collection.permalink.permalink}`, "_blank");
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } else {
      history.push(`/collection/${collection.event.id}`);
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    }
  };

  const handleEntityTrigger = (entityContainers) => {
    if (entityContainers?.permalink?.publish) {
      history.push(`/${entityContainers.permalink.permalink}`, "_blank");
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    } else {
      history.push(`/entity/${entityContainers.entity.id}`);
      if (window.scrollY) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
    }
  };

  function organizeFilteredEntities(searchedEntity) {
    if (!searchedEntity) return [];

    const publishedSearchedEntities = searchedEntity.filter(
      (container) => container?.permalink?.publish
    );

    const filteredPublishedEntity = [];

    for (const publishedSearchedEntitiy of publishedSearchedEntities) {
      filteredPublishedEntity.push(publishedSearchedEntitiy.entity);
    }

    setFilteredEntities(publishedSearchedEntities);
    return publishedSearchedEntities;
  }

  return (
    <>
      <CustomNavbar userProfile={profileData} />
      {!collectionLoading && !entityLoading ? (
        <div className="container pt-4 pb-1">
          {!isEmpty(filteredEntities) ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between"
                }}
              >
                <h1 className="pb20-lh32 mb-3">
                  Search results for {getKeyword()}
                </h1>
              </div>
              {filteredEntities.map((entityGroup, index) => (
                <div className="mb-4" key={index}>
                  <div className="collection-grid">
                    <LazyLoadComponent key={entityGroup.entity.id}>
                      <ClientFoldersLayout
                        thumbnail={getImageUrl(
                          entityGroup.entity?.image?.bucket_path
                        )}
                        entityName={entityGroup.entity.name}
                        handleCardClick={() => handleEntityTrigger(entityGroup)}
                      />
                    </LazyLoadComponent>
                  </div>
                </div>
              ))}
            </>
          ) : getKeyword() ? (
            <div style={{ padding: 10 }}>
              <h1 className="pb20-lh32">
                No search results for {getKeyword()}
              </h1>
            </div>
          ) : (
            <>
              {!isEmpty(promotions) &&
                promotions.map((promotionsGroup, index) => (
                  <div className="mb-4" key={index}>
                    <h1 className="pb20-lh32 mb-3">{promotionsGroup.name}</h1>
                    <div className="collection-grid">
                      {promotionsGroup.events?.map((item) => (
                        <LazyLoadComponent key={item.event.id}>
                          <ClientFoldersLayout
                            thumbnail={getImageUrl(
                              item?.event.image?.bucket_path
                            )}
                            collectionName={item.event.name}
                            createdAt={moment(item.event.start_date).format(
                              "MMMM Do, YYYY"
                            )}
                            handleCardClick={() =>
                              handleCollectionTrigger(item)
                            }
                          />
                        </LazyLoadComponent>
                      ))}
                      {promotionsGroup.entities?.map((entityGroup) => (
                        <LazyLoadComponent key={entityGroup.entity.id}>
                          <ClientFoldersLayout
                            thumbnail={getImageUrl(
                              entityGroup.entity?.image?.bucket_path
                            )}
                            entityName={entityGroup.entity.name}
                            handleCardClick={() =>
                              handleEntityTrigger(entityGroup)
                            }
                          />
                        </LazyLoadComponent>
                      ))}
                    </div>
                  </div>
                ))}

              {!isEmpty(organizedEntities) &&
                organizedEntities.map((entitiesGroup, index) => (
                  <div className="mb-4" key={index}>
                    <h1 className="pb20-lh32 mb-3">{entitiesGroup.name}</h1>
                    <div className="collection-grid">
                      {entitiesGroup.entities.map((entityGroup) => (
                        <LazyLoadComponent key={entityGroup.entity.id}>
                          <ClientFoldersLayout
                            thumbnail={getImageUrl(
                              entityGroup.entity?.image?.bucket_path
                            )}
                            entityName={entityGroup.entity.name}
                            handleCardClick={() =>
                              handleEntityTrigger(entityGroup)
                            }
                          />
                        </LazyLoadComponent>
                      ))}
                    </div>
                  </div>
                ))}

              {!isEmpty(organizedCollections) &&
                organizedCollections.map((eventsGroup, index) => (
                  <div className="mb-4" key={index}>
                    <h1 className="pb20-lh32 mb-3">{eventsGroup.name}</h1>
                    <div className="collection-grid">
                      {eventsGroup.events.map((collection) => (
                        <LazyLoadComponent key={collection.event.id}>
                          <ClientFoldersLayout
                            thumbnail={getImageUrl(
                              collection?.event.image?.bucket_path
                            )}
                            collectionName={collection.event.name}
                            createdAt={moment(
                              collection.event.start_date
                            ).format("MMMM Do, YYYY")}
                            handleCardClick={() =>
                              handleCollectionTrigger(collection)
                            }
                          />
                        </LazyLoadComponent>
                      ))}
                    </div>
                  </div>
                ))}

              {isEmpty(organizedCollections) && isEmpty(organizedEntities) && (
                <div className="userCenter">
                  <h1 className="mb-4 pr20-lh32 high-emphasis-color text-center">
                    You have no items to view yet.
                  </h1>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <CustomSpinner />
      )}
    </>
  );
};

export default Collections;
